(function ($) {
  $(window).load(function(){

    // toggle homepage banner on mobile
    if (device.mobile()) {
      $('.home .eyecatcher .vimeo_video').remove();
    } else {
      $('.home .eyecatcher .image_collection').remove();
    }

    $('.owl-carousel:not(.slider)').owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // animation
      animateIn: 'fadeIn',
      animateOut: 'fadeOut',

      // navigation
      nav: false,
      navText: ['<i class="fas fa-chevron-left"></i>', '<i class="fas fa-chevron-right"></i>'],

      dots: false,

      // drag options
      mouseDrag: false
    })

    $(".slider").owlCarousel({
      // slider settings
      autoplay: true,
      autoplayHoverPause: true,
      loop: true,
      items: 1,
      margin: 15,

      // slide speed
      autoplayTimeout: 7000,
      smartSpeed: 500,

      // navigation
      nav: true,
      navContainer: false,
      navText: ['<i class=\'fas fa-chevron-left\'></i>', '<i class=\'fas fa-chevron-right\'></i>'],

      dots: true,

      // drag options
      mouseDrag: true,

      // responsive
      responsive: {
        0: {
          items: 1
        },
        576: {
          items: 2
        },
        768: {
          items: 2
        },
        992: {
          items: 3
        },
        1200: {
          items: 3
        }
      }
    });

  });

  $(document).ready(function(){

    var windowWidth = $(window).width();
    if (windowWidth >= 1200) {
      $(window).scroll(function(){
        if ($(window).scrollTop() > 0) {
          if ( !($('body').hasClass(".accommodation_book")) ) {
            $('body').addClass('sticky');
            $('.header').addClass('sticky');
            $('.header').removeClass('sticky');


          }
        }
        else {
          $('body').removeClass('sticky');
          $('.header').removeClass('sticky');
        }
      });
    }

    // mini-sab
    /*var seachBookWidget = $('.mini-sab');
    if (seachBookWidget.length) {
        var fixedSeachBookWidget = $('.mini-sab').offset().top;
        $(window).scroll(function () {
            if ($(window).scrollTop() > fixedSeachBookWidget) {
                $('.mini-sab').addClass('fixed');
            } else {
                $('.mini-sab').removeClass('fixed');
            }
        });
    }*/

    // navbar-toggler
    $(document).on('click', '.navbar-toggler', function() {
      $('body').toggleClass('header-navbar-active');
    });

    $('a[data-fancybox]').fancybox({
      buttons: [
          //"zoom",
          //"share",
          //"slideShow",
          //"fullScreen",
          //"download",
          "thumbs",
          "close"
      ]
    });

    // make kasabon in smooth layout sticky
		require("../../vendor/w3media/framework/assets/js/V2/hc-sticky");
		$(".w3media-booking-receipt-widget").hcSticky({
			stickTo: ".content-section",
			top: 15,
			responsive: {
				992: {
					disable: true,
				},
			},
		});
  });
})(jQuery);
